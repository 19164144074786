<template>
    <div class="dashboard-reporting-history-view">
        <div class="dashboard-reporting-history-view__filters">
            <BaseInput
                :debounce="400"
                :placeholder="$t('searchPlaceholder')"
                block
                trim
                @input="nameSearchQuery = $event"
            >
                <template #icon>
                    <SearchIcon width="16" height="16" />
                </template>
            </BaseInput>
        </div>

        <div class="dashboard-reporting-history-view__content">
            <ReportingHistoryTable :filter-name="nameSearchQuery" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseInput from '../redesigned/BaseInput'
import ReportingHistoryTable from '../ReportingHistoryTable'
import SearchIcon from '../icons/SearchIcon'

export default {
    name: 'DashboardReportingHistoryView',
    components: {
        BaseInput,
        ReportingHistoryTable,
        SearchIcon,
    },
    data() {
        return {
            nameSearchQuery: '',
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapState('maintenance', ['maintenancePolicies']),
    },
}
</script>

<i18n>
{
    "en": {
        "searchPlaceholder": "Name"
    },
    "de": {
        "searchPlaceholder": "Name"
    },
    "fr": {
        "searchPlaceholder": "Nom"
    },
    "it": {
        "searchPlaceholder": "Nome"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-reporting-history-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 1.5rem 1rem 0.5rem 2rem;
        background-color: $color-gray-lighter-new;
        border-bottom: $style-border;

        & > * {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .base-input,
        .multiselect {
            max-width: 200px;
        }
    }

    &__content {
        overflow-y: auto;
    }

    @include respond-to('for-small-mobile-only') {
        &__filters {
            padding: 1rem 1rem 0;

            & > * {
                margin-right: 0;
            }

            .base-input,
            .multiselect {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
</style>
