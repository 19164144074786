<template>
    <div v-infinite-scroll="loadMore" infinite-scroll-distance="25">
        <div v-if="isFetching" class="l-padded">
            <VSpinner size="medium" :line-fg-color="spinnerColor" :speed="1" />
        </div>

        <div
            v-else-if="!list.length"
            class="l-padded l-inline l-center t-subtle t-small"
        >
            <span>
                {{ $t('shared.noSearchResult') }}
            </span>
        </div>

        <table v-else>
            <thead>
                <tr>
                    <th>
                        {{ $t('name') }}

                        <IconButton @click="handleSort('name')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'name' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('createdBy') }}

                        <IconButton @click="handleSort('created_by')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'created_by' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('createdAt') }}

                        <IconButton @click="handleSort('created')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'created' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('data') }}
                    </th>

                    <th>
                        {{ $t('template') }}
                    </th>

                    <th>
                        {{ $t('actions') }}
                    </th>
                </tr>
            </thead>

            <template v-for="item in listPortion">
                <tr :key="item.id">
                    <td>
                        {{ item.name }}
                    </td>

                    <td>
                        {{ item.created_by }}
                    </td>

                    <td>
                        {{ item.createdFormatted }}
                    </td>

                    <td>
                        <a
                            v-if="item.data_url"
                            :href="item.data_url"
                            download
                            :title="$t('download')"
                        >
                            <IconButton>
                                <DownloadIcon />
                            </IconButton>
                        </a>
                    </td>

                    <td>
                        <div
                            v-if="item.rendered_template_url"
                            class="l-inline l-gap-1"
                        >
                            <a
                                :href="item.rendered_template_url"
                                target="_blank"
                                :title="$t('show')"
                            >
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </a>

                            <a
                                :href="item.rendered_template_url"
                                target="_blank"
                                download
                                :title="$t('download')"
                            >
                                <IconButton>
                                    <DownloadIcon />
                                </IconButton>
                            </a>
                        </div>
                    </td>

                    <td>
                        <IconButton @click="handleDelete(item.id)">
                            <TrashBinIcon />
                        </IconButton>
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import moment from 'moment-timezone'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import DownloadIcon from '@/components/icons/DownloadIcon'
import IconButton from '@/components/IconButton'
import SearchIcon from '@/components/icons/SearchIcon'
import SortArrowIcon from '@/components/icons/SortArrowIcon'
import TrashBinIcon from '@/components/icons/TrashBinIcon'

const defaultScrollLimit = 15
const defaultSortColumn = 'created'
const defaultSortDirection = 1

export default {
    name: 'ReportingHistoryTable',
    components: {
        DownloadIcon,
        IconButton,
        SearchIcon,
        SortArrowIcon,
        TrashBinIcon,
        VSpinner,
    },
    directives: {
        infiniteScroll,
    },
    props: {
        filterName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isFetching: false,
            list: [],
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            sortBy: defaultSortColumn,
            sortDirection: defaultSortDirection,
            spinnerColor: process.env.VUE_APP_COLOR_PRIMARY,
        }
    },
    computed: {
        listFiltered() {
            let result = this.list

            if (this.filterName) {
                const query = this.filterName.toLowerCase()
                result = result.filter(item =>
                    item.name.toLowerCase().includes(query)
                )
            }

            if (this.sortBy) {
                result = [...result].sort((a, b) => {
                    const aValue = a[this.sortBy]
                    const bValue = b[this.sortBy]

                    if (this.sortBy === 'created') {
                        return (
                            this.sortDirection *
                            (new Date(bValue).getTime() -
                                new Date(aValue).getTime())
                        )
                    } else if (aValue === bValue) {
                        return 0
                    } else if (aValue == null) {
                        return 1
                    } else if (bValue == null) {
                        return -1
                    } else if (typeof aValue === 'string') {
                        return aValue.localeCompare(bValue) * this.sortDirection
                    }

                    return aValue > bValue
                        ? this.sortDirection
                        : -this.sortDirection
                })
            }

            return result
        },
        listPortion() {
            return this.listFiltered.length === this.list.length
                ? this.listFiltered.slice(0, this.scrollLimit)
                : this.listFiltered
        },
    },
    mounted() {
        this.fetchReportingHistory()
    },
    methods: {
        async fetchReportingHistory() {
            this.isFetching = true
            const { data } = await httpHelper.get('/reports/')
            this.list = data.results.map(item => ({
                ...item,
                createdFormatted: this.formatTimestamp(item.created),
            }))
            this.isFetching = false
        },
        formatTimestamp(timestamp) {
            return moment(timestamp).format('DD.MM.YYYY HH:mm')
        },
        async handleDelete(reportId) {
            this.isFetching = true
            await httpHelper.drop(`/reports/${reportId}/`)
            await this.fetchReportingHistory()
        },
        handleSort(column) {
            if (this.sortBy !== column || this.sortDirection !== 1) {
                this.sortBy = column
                this.sortDirection = 1
            } else if (this.sortDirection === 1) {
                this.sortDirection = -1
            }
        },
        loadMore() {
            if (this.scrollLimit < this.list.length) {
                this.scrollLimit += this.scrollStep
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "actions": "Actions",
        "createdAt": "Created at",
        "createdBy": "Created by",
        "data": "Data",
        "download": "Download",
        "name": "Name",
        "show": "Show",
        "template": "Report"
    },
    "de": {
        "actions": "Aktionen",
        "createdAt": "Erstellt am",
        "createdBy": "Erstellt von",
        "data": "Daten",
        "download": "Herunterladen",
        "name": "Name",
        "show": "Anzeigen",
        "template": "Report"
    },
    "fr": {
        "actions": "Actions",
        "createdAt": "Créé à",
        "createdBy": "Créé par",
        "data": "Data",
        "download": "Télécharger",
        "name": "Nom",
        "show": "Afficher",
        "template": "Rapport"
    },
    "it": {
        "actions": "Azione",
        "createdAt": "Creato a",
        "createdBy": "Creato da",
        "data": "Dati",
        "download": "Scaricare",
        "name": "Nome",
        "show": "Mostra",
        "template": "Rapporto"
    }
}
</i18n>

<style lang="scss" scoped>
table {
    display: block;
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    thead {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;

        .icon-button {
            margin-left: 5px;
        }
    }

    tr {
        &:not(:first-of-type) {
            td {
                border-top: $style-border;
            }
        }

        th {
            text-align: left;
            border-bottom: $style-border;
        }

        th,
        td {
            padding: 1rem;
            white-space: nowrap;

            &:first-child {
                padding-left: 2rem;
            }

            &:last-child {
                padding-right: 2rem;
            }

            &:not(:last-child) {
                width: 1%;
            }
        }
    }

    a {
        display: block;

        .icon-button {
            display: block;
        }
    }
}
</style>
